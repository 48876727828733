<template>
  <div class="my-style">
    <div
      class="box1"
      @click="goAhead('/myinfo')"
    >
      <div class="box1-pos" :style="{
        backgroundImage: `url(${$http.imgurl + user.bannerimage})`,
      }"></div>
      <!-- <van-image round :src="$http.imgurl + user.avatar" class="b1img" /> -->
      <img :src="$http.imgurl + user.avatar" alt="" class="b1img">
    </div>
    <div class="box2 column-center">
      <div class="b2-t1">{{ user.nickname ? user.nickname : $t('text.t31') }}</div>
      <div class="b2-t3">
        {{$t('text.t32')}}：{{ user.intro ? user.intro : $t('text.t33') }}
      </div>
    </div>
    <!-- <div class="box5 evenly-center">
      <div>
        <div class="b5-t1">{{user.fans}}</div>
        <div class="b5-t2">{{$t('text.t34')}}</div>
      </div>
      <div>
        <div class="b5-t1">{{user.attention}}</div>
        <div class="b5-t2">{{$t('text.t35')}}</div>
      </div>
      <div>
        <div class="b5-t1">{{user.putaway}}</div>
        <div class="b5-t2">{{$t('text.t36')}}</div>
      </div>
    </div> -->
    <div>
      <van-tabs
        title-active-color="#323232"
        title-inactive-color="#909090"
        v-model="active"
        animated
        color="#323232"
        :line-width="80"
        sticky
      >
        <van-tab :title="$t('text.t37')">
          <div class="box3 between-center">
            <div class="b3-b1">
              <van-search
                background="#fff"
                shape="round"
                v-model="value0"
                :placeholder="$t('text.t38')"
                @search="onSearch0"
              />
            </div>
            <!-- <img
              @click="showCheck = !showCheck"
              src="@/assets/img/img47.png"
              alt=""
              class="b3-img1"
            /> -->
          </div>
          <van-checkbox-group v-model="result">
            <van-list
              v-model="loading0"
              :finished="finished0"
              :finished-text="$t('text.t39')"
              :offset="10"
              @load="getlist0"
            >
              <div class="box4 start-center">
                <div
                  class="item center-center"
                  v-for="item in list0"
                  :key="item.id"
                >
                  <div
                    class="itembox"
                    @click="goAhead(`/minelists?addr=${item.contract}`)"
                  >
                    <div class="itembox-pos" v-if="showCheck" @click.stop>
                      <van-checkbox :name="item.id">
                        <template #icon="props">
                          <img
                            v-if="props.checked"
                            class="itembox-pos-icon"
                            src="@/assets/img/img52.png"
                          />
                          <img
                            v-else
                            class="itembox-pos-icon"
                            src="@/assets/img/img51.png"
                          />
                        </template>
                      </van-checkbox>
                    </div>
                    <div class="ib-imgbox">
                      <img
                        v-if="item.status == 1"
                        :src="item.image"
                        alt=""
                        class="ib-img"
                      />
                      <img
                        v-else-if="item.status == 0 || item.status == 3"
                        :src="
                          language == '1'
                            ? $concatImg('img60')
                            : $concatImg('img61')
                        "
                        alt=""
                        class="ib-img"
                      />
                      <img
                        v-else-if="item.status == 2"
                        :src="
                          language == '1'
                            ? $concatImg('img62')
                            : $concatImg('img63')
                        "
                        alt=""
                        class="ib-img"
                      />
                    </div>
                    <div class="ib-info">
                      <div class="ib-t5">
                        {{ item.title }}
                      </div>
                      <div class="ib-t4 start-center">
                        <div class="ib-t4-t1">*{{ item.number }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </van-list>
          </van-checkbox-group>
        </van-tab>
        <van-tab :title="$t('text.t40')">
          <div class="box3 between-center">
            <div class="b3-b1">
              <van-search
                background="#fff"
                shape="round"
                v-model="value1"
                :placeholder="$t('text.t41')"
                @search="onSearch1"
              />
            </div>
            <!-- <img
              src="@/assets/img/img48.png"
              alt=""
              @click="goAhead('/createcollection')"
              class="b3-img1"
            /> -->
          </div>
          <van-list
            v-model="loading1"
            :finished="finished1"
            :finished-text="$t('text.t39')"
            :offset="10"
            @load="getlist1"
          >
            <div class="box7-list start-center">
              <div
                class="b7-item-add center-center"
                v-for="item in list1"
                :key="item.id"
              >
                <div
                  class="b7-item"
                  @click="goAhead(`/set?id=${item.id}&isup=1&chainType=${item.chain}`)"
                >
                  <div class="b7item-i1">
                    <img
                      :src="item.selectedimage"
                      alt=""
                      class="b7item-i1-img"
                    />
                  </div>
                  <div class="b7item-b1 end-center">
                    <div class="b7item-bi-t1">
                      <div>{{ item.collectionname }}</div>
                      <div class="start-center b7item-bi-t1-t2">
                        <img
                          src="@/assets/img/img20.png"
                          class="b7item-bi-t1-t2-i1"
                        />
                        <div class="b7item-bi-t1-t2-t1">{{ item.number }}</div>
                      </div>
                    </div>
                    <img
                      @click.stop="goAhead(`/createcollection?id=${item.id}&chainType=${item.chain}`)"
                      src="@/assets/img/img59.png"
                      alt=""
                      class="add-t1"
                    />
                    <div class="b7item-bi-pos">
                      <img
                        :src="item.collectionimage"
                        alt=""
                        class="b7item-bi-pos-img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </van-list>
        </van-tab>
        <van-tab :title="$t('text.t42')">
          <div class="box3 between-center">
            <div class="b3-b1">
              <van-search
                background="#fff"
                shape="round"
                v-model="value2"
                :placeholder="$t('text.t43')"
                @search="onSearch2"
              />
            </div>
          </div>
          <van-list
            v-model="loading2"
            :finished="finished2"
            :finished-text="$t('text.t39')"
            :offset="10"
            @load="getlist2"
          >
            <div class="box4 start-center">
              <div
                class="item center-center"
                v-for="item in list2"
                :key="item.id"
              >
                <div class="itembox" @click="goAhead(`/newdetail?orderno=${item.orderno}&chainType=${item.chain}`)">
                  <div class="ib-imgbox">
                    <img :src="item.info.image" alt="" class="ib-img" />
                  </div>
                  <div class="ib-info">
                    <div class="ib-t1">
                      {{ item.title }} #{{ item.tokenid }}
                    </div>
                    <div class="ib-t2">{{$t('text.t44')}}：</div>
                    <div class="ib-t3 start-center">
                      <img
                        src="@/assets/img/img17.png"
                        alt=""
                        class="ib-t3-img"
                      />
                      <div class="ib-t3-t1">{{item.price}}</div>
                    </div>
                    <div class="ib-t4 start-center">
                      <img
                        src="@/assets/img/img18.png"
                        alt=""
                        class="ib-t4-img"
                      />
                      <div class="ib-t4-t1">{{ item.info.heat }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </van-list>
        </van-tab>
        <van-tab :title="$t('text.t45')">
          <div class="box3 between-center">
            <div class="b3-b1">
              <van-search
                background="#fff"
                shape="round"
                v-model="value3"
                :placeholder="$t('text.t41')"
                @search="onSearch3"
              />
            </div>
          </div>
          <van-list
            v-model="loading3"
            :finished="finished3"
            :finished-text="$t('text.t39')"
            :offset="10"
            @load="getlist3"
          >
            <div class="box7-list start-center">
              <div
                class="b7-item-add center-center"
                v-for="item in list3"
                :key="item.id"
              >
                <div
                  class="b7-item"
                  @click="goAhead(`/set?id=${item.id}&chainType=${item.chain}`)"
                >
                  <div class="b7item-i1">
                    <img
                      :src="item.selectedimage"
                      alt=""
                      class="b7item-i1-img"
                    />
                  </div>
                  <div class="b7item-b1 end-center">
                    <div class="b7item-bi-t1">
                      <div class="van-ellipsis">{{ item.collectionname }}</div>
                      <div class="b7item-bi-t1-t2 start-center">
                        <img
                          src="@/assets/img/img20.png"
                          alt=""
                          class="b7item-bi-t1-t2-i1"
                        />
                        <div class="b7item-bi-t1-t2-t1">{{ item.number }}</div>
                      </div>
                    </div>
                    <div class="b7item-bi-pos">
                      <img
                        :src="item.collectionimage"
                        alt=""
                        class="b7item-bi-pos-img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </van-list>
        </van-tab>
        <van-tab :title="$t('text.t46')">
          <div class="box3 between-center">
            <div class="b3-b1">
              <van-search
                background="#fff"
                shape="round"
                v-model="value4"
                :placeholder="$t('text.t38')"
                @search="onSearch4"
              />
            </div>
          </div>
          <div class="add-get end-center">
            <!-- <div class="item1">{{$t('text.t529')}}:{{copyrightFee}}</div>
            <div class="item2"><van-button plain round hairline :loading="crLoading" size="small" color="#323232" type="primary" @click="receiveCopyright">{{$t('text.t530')}}</van-button></div> -->
            <div class="item3" @click="goAhead('/copyrightlist')">版费记录</div>
          </div>
          <van-list
            v-model="loading4"
            :finished="finished4"
            :finished-text="$t('text.t39')"
            :offset="10"
            @load="getlist4"
          >
            <div class="box4 start-center">
              <div class="item center-center" v-for="item in list4"
                :key="item.id">
                <div class="itembox" @click="goAhead(`/minelists?addr=${item.contract}`)">
                  <div class="ib-imgbox">
                    <img :src="item.image" alt="" class="ib-img" />
                  </div>
                  <div class="ib-info">
                    <div class="ib-t6 between-center">
                      <div>{{item.symbol}}</div>
                      <div class="ib-t6-r">x{{item.number}}</div>
                    </div>
                    <div class="ib-t4">
                      <div class="ib-t4-t1">{{$parseTime(item.createtime,"{y}/{m}/{d}")}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </van-list>
        </van-tab>
        <van-tab :title="$t('text.t47')">
          <div class="box3 between-center">
            <div class="b3-b1">
              <van-search
                background="#fff"
                shape="round"
                v-model="value5"
                :placeholder="$t('text.t38')"
                @search="onSearch5"
              />
            </div>
            <img
              src="@/assets/img/img32.png"
              alt=""
              @click="goAhead('/buylist')"
              class="b3-img1"
            />
          </div>
          <van-list
            v-model="loading5"
            :finished="finished5"
            :finished-text="$t('text.t39')"
            :offset="10"
            @load="getlist5"
          >
            <div class="box4 start-center">
              <div
                class="item center-center"
                v-for="item in list5"
                :key="item.id"
              >
                <div class="itembox" @click="goAhead(`/newdetail?orderno=${item.orderno}&chainType=${item.chain}`)">
                  <div class="ib-imgbox">
                    <img :src="item.info.image" alt="" class="ib-img" />
                  </div>
                  <div class="ib-info">
                    <div class="ib-t6">{{item.info.title}}</div>
                    <div class="ib-t2">{{$t('text.t44')}}：</div>
                    <div class="ib-t3 start-center">
                      <img
                        src="@/assets/img/img17.png"
                        alt=""
                        class="ib-t3-img"
                      />
                      <div class="ib-t3-t1">{{item.price}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </van-list>
        </van-tab>
        <!-- <van-tab :title="$t('text.t48')">
          <div class="box3 between-center">
            <div class="b3-b1">
              <van-search
                background="#fff"
                shape="round"
                v-model="value6"
                :placeholder="$t('text.t49')"
                @search="onSearch6"
              />
            </div>
          </div>
          <van-list
            v-model="loading6"
            :finished="finished6"
            :finished-text="$t('text.t39')"
            :offset="10"
            @load="getlist6"
          >
          <div class="box7-list start-center">
            <div
              class="b7-item-add center-center"
              v-for="item in list6"
              :key="item.id"
            >
              <div class="b7-item">
                <div class="b7item-i1">
                  <van-image fit="cover" :src="$http.imgurl+item.bannerimage" class="b7item-i1-img" />
                </div>
                <div class="b7item-b1 end-center">
                  <div class="b7item-bi-t1">
                    <div class="van-ellipsis">{{item.nickname ? item.nickname : $t('text.t40')}}</div>
                    <div class="b7item-bi-t1-t2 start-center">
                      <div class="b7item-bi-t1-t2-t1 van-ellipsis">
                        {{item.intro ? item.intro : $t('text.t33')}}
                      </div>
                    </div>
                  </div>
                  <div class="b7item-bi-pos">
                    <img
                      :src="$http.imgurl+item.avatar"
                      alt=""
                      class="b7item-bi-pos-img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          </van-list>
        </van-tab> -->
      </van-tabs>
    </div>
    <div class="bot-box between-center" v-if="showBot">
      <div class="botbox-left start-center">
        <div class="botbox-left-item" v-for="item in showlist" :key="item.id">
          <img
            v-if="item.status == 1"
            :src="item.image"
            alt=""
            class="botbox-left-item-i"
          />
          <img
            v-else-if="item.status == 0"
            :src="
              language == '1'
                ? $concatImg('img60')
                : $concatImg('img61')
            "
            alt=""
            class="botbox-left-item-i"
          />
          <img
            v-else-if="item.status == 2"
            :src="
              language == '1'
                ? $concatImg('img62')
                : $concatImg('img63')
            "
            alt=""
            class="botbox-left-item-i"
          />
          <div class="botbox-left-item-t">#{{ item.tokenId }}</div>
        </div>
      </div>
      <div class="botbox-right">
        <div class="botbox-right-t1 center-center" @click="show = true">
          {{$t('text.t51')}}
        </div>
        <div class="botbox-right-t2 center-center" @click="cancels">{{$t('text.t52')}}</div>
      </div>
    </div>
    <el-dialog
      :visible.sync="show"
      :show-close="false"
      :width="device == 'h5' ? '90%' : '36%'"
    >
      <div class="pop-box">
        <div class="pop-box-t1">{{$t('text.t51')}}</div>
        <div class="pop-box-t2">{{$t('text.t53')}}</div>
        <div class="pop-box-t3">
          <el-select
            v-model="standard"
            :placeholder="$t('text.t54')"
            @change="schange"
          >
            <el-option
              v-for="item in collectionList"
              :key="item.id"
              :label="item.collectionname"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="pop-box-t4 center-center" @click="moveNft">{{$t('text.t55')}}</div>
      </div>
    </el-dialog>
    <!-- <van-popup round v-model="show" close-on-popstate>
      <div class="pop-box">
        <div class="pop-box-t1">更改合集</div>
        <div class="pop-box-t2">合集</div>
        <div class="pop-box-t3">
          <el-select v-model="standard" placeholder="请选择合集" @change="schange">
            <el-option
              v-for="item in collectionList"
              :key="item.id"
              :label="item.collectionname"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="pop-box-t4 center-center" @click="moveNft">移动</div>
      </div>
    </van-popup> -->
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  components: {},
  data() {
    return {
      value: "",
      active: 0,
      result: [],
      show: false,
      showCheck: false,
      options: [
        {
          value: "1",
          label: "svbdvkjbabhjkgb",
        },
        {
          value: "2",
          label: "svbdvkjbabhjkgb",
        },
      ],
      standard: "",
      isloading: false,
      limit: 20,

      page0: 1,
      loading0: false,
      finished0: false,
      list0: [],
      value0: "",

      page1: 1,
      loading1: false,
      finished1: false,
      list1: [],
      value1: "",

      page2: 1,
      loading2: false,
      finished2: false,
      list2: [],
      value2: "",

      page3: 1,
      loading3: false,
      finished3: false,
      list3: [],
      value3: "",

      page4: 1,
      loading4: false,
      finished4: false,
      list4: [],
      value4: "",

      page5: 1,
      loading5: false,
      finished5: false,
      list5: [],
      value5: "",
      
      page6: 1,
      loading6: false,
      finished6: false,
      list6: [],
      value6: "",

      copyrightFee:0,
      crLoading:false,
      tmsg:''
    };
  },
  created() {
    this.update721_1155()
    this.getUser();
    // this.getMyCollection();
  },
  watch:{
    showlist(val){
      console.log(val);
    }
  },
  computed: {
    ...mapState(["user", "collectionList", "config","chainId", "language", "device"]),
    showBot() {
      return this.result.length > 0 ? true : false;
    },
    showlist() {
      let arr = [];
      for (let i = 0; i < this.result.length; i++) {
        for (let j = 0; j < this.list0.length; j++) {
          if (this.result[i] == this.list0[j].id) {
            arr.push(this.list0[j]);
          }
        }
      }
      return arr;
    },
  },
  activated(){
    
  },
  mounted() {
    this.$bus.$on("update", () => {
      this.onSearch1();
    });
  },
  methods: {
    ...mapActions(["getUser", "getMyCollection"]),
    update721_1155(){
      this.$http.post('/collections/userAsset721')
      this.$http.post('/collections/userAsset1155')
    },
    receiveCopyright(){
      this.crLoading = true
      this.$http.post('/casting/feeOrder',{language:this.language}).then(res=>{
        const {address,orderno} = res.data
        this.copyrightMethond(address,orderno)
      })
    },
    copyrightMethond(address,orderno) {
      const web3 = new this.Web3(ethereum);
      const contract_to =
        this.chainId == "0x2d20"
          ? this.config.omp_market_contract
          : this.chainId == "0x38"
          ? this.config.bsc_market_contract
          : this.chainId == "0x1"
          ? this.config.eth_market
          : this.chainId == "0x89"
          ? this.config.pol_market
          : "";
      const abi_to =
        this.chainId == "0x2d20"
          ? JSON.parse(this.config.omp_market_abi)
          : JSON.parse(this.config.bsc_market_abi);
      const nft_contract = new web3.eth.Contract(abi_to, contract_to, {
        from: address,
      });
      
      nft_contract.methods
        .claimPendingRevenue()
        .send({ from: address })
        .on("transactionHash", async (hash) => {
          const rest = await this.sendHash(hash, orderno);
          this.tmsg = "";
          this.tmsg = rest.msg;
        })
        .on("receipt", (receipt) => {
          this.crLoading = false
          this.$toast(this.tmsg);
          this.getCopyright()
        })
        .on("error", (error, receipt) => {
          console.log(error, receipt);
          this.crLoading = false;
        });
    },
    async sendHash(hash, orderno) {
      const arg = {
        hash,
        orderno,
        language: this.language,
      };
      const rest = await this.$http.post("/casting/feeHash", arg);
      return rest;
    },
    getCopyright(){
      this.$http.get('/casting/checkFee',{language:this.language}).then(res=>{
        this.copyrightFee = res.data
      })
    },
    moveNft() {
      this.isloading = true;
      let arg = {
        collection_id: this.standard,
        ids: this.result.join(","),
        language: this.language,
      };
      this.$http
        .post("/collection/batches", arg)
        .then((res) => {
          this.$toast(res.msg);
          this.standard = "";
          this.show = false;
          this.cancels();
          this.isloading = false;
        })
        .catch(() => {
          this.isloading = false;
        });
    },
    cancels() {
      this.result = [];
      this.showCheck = false;
    },
    onSearch0() {
      this.loading0 = false;
      this.finished0 = false;
      this.page0 = 1;
      this.list0 = [];
    },
    getlist0() {
      let arg = {
        page: this.page0,
        limit: this.limit,
        contract: this.value0,
      };
      this.$http.get("/collection/mineList", arg).then((res) => {
        const { current_page, data, last_page } = res.data;
        if (current_page >= last_page) this.finished0 = true;
        this.page0++;
        this.loading0 = false;
        this.list0 = [...this.list0, ...data];
      });
    },
    onSearch1() {
      this.loading1 = false;
      this.finished1 = false;
      this.page1 = 1;
      this.list1 = [];
    },
    getlist1() {
      let arg = {
        page: this.page1,
        limit: this.limit,
        collectionname: this.value1,
      };
      this.$http.get("/collection/collectionList", arg).then((res) => {
        const { current_page, data, last_page } = res.data;
        if (current_page >= last_page) this.finished1 = true;
        this.page1++;
        this.loading1 = false;
        this.list1 = [...this.list1, ...data];
      });
    },
    onSearch2() {
      this.loading2 = false;
      this.finished2 = false;
      this.page2 = 1;
      this.list2 = [];
    },
    getlist2() {
      let arg = {
        page: this.page2,
        limit: this.limit,
        title: this.value2,
      };
      this.$http.get("/collection/likeList", arg).then((res) => {
        const { current_page, data, last_page } = res.data;
        if (current_page >= last_page) this.finished2 = true;
        this.page2++;
        this.loading2 = false;
        this.list2 = [...this.list2, ...data];
      });
    },
    onSearch3() {
      this.loading3 = false;
      this.finished3 = false;
      this.page3 = 1;
      this.list3 = [];
    },
    getlist3() {
      let arg = {
        page: this.page3,
        limit: this.limit,
        collectionname: this.value3,
      };
      this.$http.get("/collection/enshrineList", arg).then((res) => {
        const { current_page, data, last_page } = res.data;
        if (current_page >= last_page) this.finished3 = true;
        this.page3++;
        this.loading3 = false;
        this.list3 = [...this.list3, ...data];
      });
    },
    onSearch4() {
      this.loading4 = false;
      this.finished4 = false;
      this.page4 = 1;
      this.list4 = [];
    },
    getlist4() {
      let arg = {
        page: this.page4,
        limit: this.limit,
        contract: this.value4,
        language:this.language
      };
      this.$http.get("/casting/castingList", arg).then((res) => {
        const { current_page, data, last_page } = res.data;
        if (current_page >= last_page) this.finished4 = true;
        this.page4++;
        this.loading4 = false;
        this.list4 = [...this.list4, ...data];
      });
    },
    onSearch5() {
      this.loading5 = false;
      this.finished5 = false;
      this.page5 = 1;
      this.list5 = [];
    },
    getlist5() {
      let arg = {
        page: this.page5,
        limit: this.limit,
        title: this.value5,
      };
      this.$http.get("/order/pendingOrderList", arg).then((res) => {
        const { current_page, data, last_page } = res.data;
        if (current_page >= last_page) this.finished5 = true;
        this.page5++;
        this.loading5 = false;
        this.list5 = [...this.list5, ...data];
      });
    },
    onSearch6() {
      this.loading6 = false;
      this.finished6 = false;
      this.page6 = 1;
      this.list6 = [];
    },
    getlist6() {
      let arg = {
        page: this.page6,
        limit: this.limit,
        nickname: this.value6,
      };
      this.$http.get("/order/fansList", arg).then((res) => {
        const { current_page, data, last_page } = res.data;
        if (current_page >= last_page) this.finished6 = true;
        this.page6++;
        this.loading6 = false;
        this.list6 = [...this.list6, ...data];
      });
    },
    schange(e) {
      if (e == -1) {
        this.standard = "";
        this.goAhead("/createcollection");
      }
    },
    onSearch(e) {
      console.log(e);
    },
  },
  beforeRouteLeave(to, from, next) {
    this.cancels();
    const whitelist = [
      "Createcollection",
      "Minelists",
      "Myinfo",
      "Collectionshop",
      "Buylist",
      "Nftdetail",
      "Castinglist",
      "Newdetail",
      "Set",
      "Copyrightlist"
    ];
    console.log(whitelist.includes(to.name));
    if (whitelist.includes(to.name)) {
      from.meta.keepAlive = true;
    } else {
      from.meta.keepAlive = false;
    }
    next();
  },
};
</script>

<style lang="less">
.my-style {
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog {
    border-radius: 20px;
  }
  .box1 {
    height: 177px;
    position: relative;
    margin-bottom: 11px;
    .box1-pos{
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 138px;
    }
    .b1img {
      width: 81px;
      height: 81px;
      border-radius: 50%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
    }
  }
  .box2 {
    width: 316px;
    margin: auto;
    .b2-t1 {
      font-size: 30px;
      font-weight: bold;
      color: #323232;
      margin-bottom: 10px;
    }
    .b2-t3 {
      font-size: 9px;
      font-weight: 500;
      color: #323232;
      margin-bottom: 20px;
    }
  }
  .box5 {
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 16px;
    font-weight: bold;
    color: #323232;
    text-align: center;
    .b5-t1 {
      font-size: 18px;
      margin-bottom: 10px;
      line-height: 1;
    }
    .b5-t2 {
      font-size: 12px;
    }
  }
  .box3 {
    border-top: 1px solid #e1e1e1;
    // width: 375px;
    margin: auto;
    .van-search__content--round {
      border: 1px solid #000;
    }
    .van-search__content {
      background-color: #fff;
    }
    .b3-b1 {
      flex: 1;
    }
    .b3-img1 {
      width: 20px;
      height: 20px;
      margin: 0 12px;
    }
  }
  .add-get{
    padding: 0 20px 20px;
    color: #323232;
    .item1{
      font-size: 12px;
    }
    .item3{
      font-size: 12px;
      text-decoration: underline;
    }
  }
  .box4 {
    // margin: auto;
    padding: 0 11px;
    flex-wrap: wrap;
    .item {
      width: 176px;
      margin-bottom: 15px;
      .itembox {
        box-shadow: 0 0 6px rgba(50, 50, 50, 0.1);
        width: 150px;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        .itembox-pos {
          position: absolute;
          top: 10px;
          right: 10px;
          .itembox-pos-icon {
            width: 20px;
            height: 20px;
          }
        }
        .ib-imgbox {
          width: 150px;
          height: 159px;
          overflow: hidden;
          .ib-img {
            width: 100%;
            height: auto;
          }
        }
        .ib-info {
          color: #323232;
          font-weight: bold;
          padding: 5px 10px 10px;
          .ib-t1 {
            font-size: 12px;
            margin-bottom: 5px;
          }
          .ib-t5 {
            font-size: 12px;
            margin-bottom: 20px;
          }
          .ib-t2 {
            font-size: 9px;
            margin-bottom: 4px;
          }
          .ib-t3 {
            margin-bottom: 8px;
            .ib-t3-img {
              width: 9px;
              height: 8px;
              margin-right: 5px;
            }
            .ib-t3-t1 {
              font-size: 12px;
            }
          }
          .ib-t4 {
            .ib-t4-img {
              width: 12px;
              height: 10px;
              margin-right: 6px;
            }
            .ib-t4-t1 {
              font-size: 11px;
            }
          }
          .ib-t6 {
            font-size: 12px;
            margin-bottom: 12px;
            .ib-t6-r {
              font-size: 9px;
            }
          }
        }
      }
    }
  }
  .box7-list {
    padding: 0 11px;
    flex-wrap: wrap;
    .b7-item-add {
      width: 176px;
      margin-bottom: 21px;
    }
    .b7-item {
      width: 150px;
      background: #fff;
      border-radius: 10px;
      box-shadow: 0 0 16px rgba(50, 50, 50, 0.1);
      overflow: hidden;
      .b7item-i1 {
        width: 100%;
        height: 100px;
        overflow: hidden;
        .b7item-i1-img {
          width: 100%;
          height: 100%;
        }
      }
      .b7item-b1 {
        padding: 7px 10px;
        position: relative;
        box-sizing: border-box;
        .b7item-bi-t1 {
          font-size: 10px;
          font-weight: bold;
          color: #323232;
          width: calc(100% - 58px);
          margin-bottom: 6px;
          .b7item-bi-t1-t2 {
            margin-top: 5px;
            .b7item-bi-t1-t2-i1 {
              width: 7px;
              height: 6px;
              margin-right: 3px;
            }
            .b7item-bi-t1-t2-t1 {
              font-size: 8px;
            }
          }
        }
        .add-t1 {
          width: 3px;
          height: auto;
        }
        .b7item-bi-pos {
          position: absolute;
          left: 10px;
          top: -23px;
          width: 45px;
          height: 46px;
          border-radius: 3px;
          border: 3px solid #ffffff;
          box-shadow: 0 0 6px rgba(50, 50, 50, 0.1);
          box-sizing: border-box;
          background-color: #fff;
          .b7item-bi-pos-img {
            border-radius: 3px;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .bot-box {
    width: 100%;
    height: 80px;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #fff;
    box-shadow: 0 -6px 10px rgba(0, 0, 0, 0.1);
    .botbox-left {
      flex: 1;
      padding: 0 0 0 10px;
      overflow-x: auto;
      .botbox-left-item {
        width: 45px;
        margin-right: 20px;
        text-align: center;
        .botbox-left-item-i {
          width: 45px;
          height: 45px;
          margin-bottom: 8px;
        }
        .botbox-left-item-t {
          font-size: 9px;
          font-weight: 500;
          color: #323232;
        }
      }
    }
    .botbox-right {
      box-shadow: -6px 0 10px rgba(0, 0, 0, 0.1);
      width: 90px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      .botbox-right-t1 {
        width: 80px;
        height: 30px;
        background: #323232;
        border-radius: 15px;
        font-size: 10px;
        font-weight: bold;
        color: #ffffff;
      }
      .botbox-right-t2 {
        border: 1px solid #323232;
        width: 80px;
        height: 30px;
        background: #ffffff;
        border-radius: 15px;
        font-size: 10px;
        font-weight: bold;
        color: #323232;
      }
    }
  }
  .pop-box {
    width: 267px;
    padding: 26px 18px;
    margin: auto;
    .pop-box-t1 {
      font-size: 18px;
      font-weight: bold;
      color: #323232;
      margin-bottom: 28px;
      text-align: center;
    }
    .pop-box-t2 {
      margin-bottom: 11px;
    }
    .pop-box-t3 {
      margin-bottom: 28px;
      .el-select {
        display: block;
      }
    }
    .pop-box-t4 {
      margin: auto;
      width: 231px;
      height: 31px;
      background: #323232;
      border: 1px solid #323232;
      border-radius: 10px;
      font-size: 15px;
      font-weight: bold;
      color: #ffffff;
    }
  }
}
</style>